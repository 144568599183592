import * as React from 'react';
import { alpha } from '@mui/material';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, setState, styled } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, SvgIcon, IconButton, Grid } from '@mui/material';
import { AuthContext, NETWORK_LOADED, redirectToLogin } from '../context/AuthenticationProvider';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';


export default function Hero() {
  const { instance, status, userData } = useContext(AuthContext);
  return (
    <Box
      id='hero'
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 30 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' }, height: 500 }}>
          <Typography
            variant='h1'
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Lieber &nbsp;
            <Typography
              component='span'
              variant='h1'
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'),
              }}
            >
              Gast
            </Typography>
          </Typography>

          <Box
            sx={{
              width: { sm: '100%', md: '100%' },
              textAlign: { sm: 'center', md: 'center' },
            }}
          >
            <Typography variant='body1' sx={{ marginBottom: 3 }}>
              Hier findest du alle Details zu unsere geplanten Hochzeit <br />
              Möchtest du mehr wissen? Dann melde dich bitte an.
            </Typography>
            <Typography  sx={{fontStyle: 'italic',marginBottom: 3 }}>
              Du kannst dich ganz einfach selbst registrieren, du benötigst nur eine E-Mail-Adresse. <br /> Der Anmeldeprozess
              sollte in 1-2 Minuten abgeschlossen sein.
            </Typography>

            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography>Gleich weißt du mehr &nbsp; </Typography> <SentimentSatisfiedAltIcon />
            </Grid>
          </Box>
          <Typography sx={{ alignSelf: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                redirectToLogin(instance);
              }}
            >
              Jetzt anmelden
            </Button>{' '}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
