import * as React from 'react';
import { useContext, useEffect, setState, styled } from 'react';
import { request } from '../api/api';
import { AuthContext, NETWORK_LOADED } from '../../context/AuthenticationProvider';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { NumberInput } from '../NumControl/numcontrol';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Box, SvgIcon, Container, Typography, CircularProgress } from '@mui/material';
import { ReactComponent as BoatIcon } from '../../assets/boat.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

import { useNavigate } from 'react-router-dom';
function GridItem(props) {
  const { sx, ...other } = props;
  return (
    <Grid
      sx={{
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        m: 1,
        ...sx,
      }}
      {...other}
    />
  );
}

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        alignContent: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

export const Booking = () => {
  const { instance, status, userData } = useContext(AuthContext);
  let navigate = useNavigate();
  const [confirmation, setConfirmation] = React.useState(true);
  const [adultCount, setAdultCount] = React.useState(0);
  const [firstChildAge, setFirstChildAge] = React.useState(null);
  const [secondChildAge, setSecondChildAge] = React.useState(null);
  const [specialRequirement, setSpecialRequirement] = React.useState('');
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [openCancellation, setOpenCancellation] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  loading;
  const handleClose = () => {
    setOpenConfirmation(false);
    setOpenCancellation(false);
  };
  const handleAdultCountChange = (event, val) => {
    event.preventDefault();
    setAdultCount(val);
  };
  const handleFirstChildAgeChange = (event, val) => {
    event.preventDefault();
    setFirstChildAge(val);
  };
  const handleSecondChildAgeChange = (event, val) => {
    event.preventDefault();
    setSecondChildAge(val);
  };

  const handleSpecialRequirementsChange = (event) => {
    setSpecialRequirement(event.target.value);
  };

  const handleConfirmationChange = (event) => {
    setConfirmation(event.target.checked);
  };

  const clickUnRegister = async (event) => {
    setOpenCancellation(true);
  };

  const clickRegister = async (event) => {
    setOpenConfirmation(true);
  };

  const handleCancellation = async (event) => {
    setLoading(true);
    const data = {
      adults: 0,
      firstChildAge: null,
      secondChildAge: null,
      Comment: null,
    };
    console.log(data);
    const reserved = await request(instance, { url: '/info/register', data, method: 'post' });

    console.log(reserved);
    setLoading(false);
    setOpenCancellation(false);

    navigate('/', { replace: true });
    navigate(0);
  };
  const handleConfirmation = async (event) => {
    setLoading(true);
    const data = {
      adults: adultCount,
      firstChildAge: firstChildAge,
      secondChildAge: secondChildAge,
      Comment: specialRequirement,
    };
    console.log(data);
    const reserved = await request(instance, { url: '/info/register', data, method: 'post' });

    console.log(reserved);
    setLoading(false);
    setOpenConfirmation(false);
    navigate('/', { replace: true });
    navigate(0);
  };
  return (
    <>
      <Box
        id='booking'
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
        }}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          {status === NETWORK_LOADED && !userData.data && (
            <>
              <CircularProgress />
            </>
          )}
          {status === NETWORK_LOADED && userData.data?.responseType && userData.data?.responseType === 'unknown' && (
            <>
              <Box
                sx={{
                  width: { sm: '100%', md: '60%' },
                  textAlign: { sm: 'left', md: 'center' },
                }}
              >
                <Typography component='h2' variant='h4'>
                  Rückmeldung
                </Typography>
                <Box
                  component='form'
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1,
                    m: 1,
                  }}
                  /* noValidate*/
                  autoComplete='off'
                >
                  <Box>
                    <GridItem>
                      <Item>
                        <Switch checked={confirmation} onChange={handleConfirmationChange} />
                      </Item>
                      <Item>
                        {confirmation !== true && (
                          <>
                            {' '}
                            <Typography component='h2' variant='h4'>
                              Absage
                            </Typography>
                          </>
                        )}
                        {confirmation === true && (
                          <>
                            <Typography component='h2' variant='h4'>
                              Zusage
                            </Typography>
                          </>
                        )}
                      </Item>
                    </GridItem>
                  </Box>

                  {confirmation && (
                    <>
                      <GridItem>
                        <Typography variant='body1' color='text.primary' sx={{ marginRight: 2 }}>
                          Erwachsene:
                        </Typography>
                        <NumberInput min={0} max={6} value={adultCount} onChange={handleAdultCountChange} />
                      </GridItem>

                      <Typography variant='body1' color='text.primary' sx={{ marginTop: 2 }}>
                        Magda übernimmt das Kinder-Programm. <br />
                        Wie alt sind die kleinen Besucher?
                      </Typography>

                      <GridItem sx={{ marginTop: 2 }}>
                        <Box width={150}>1. Kind:</Box>
                        <NumberInput min={0} max={12} value={firstChildAge} onChange={handleFirstChildAgeChange} />
                        <Box paddingLeft={1}>Jahre alt</Box>
                      </GridItem>
                      <GridItem>
                        <Box width={150}>2. Kind:</Box>
                        <NumberInput min={0} max={12} value={secondChildAge} onChange={handleSecondChildAgeChange} />
                        <Box paddingLeft={1}>Jahre alt</Box>
                      </GridItem>

                      <GridItem>
                        <TextField
                          style={{ width: '80%' }}
                          id='outlined-multiline-flexible'
                          label='Besondere Bedürfnisse'
                          multiline
                          maxRows={4}
                          maxLength={300}
                          text={specialRequirement}
                          onChange={handleSpecialRequirementsChange}
                        />
                      </GridItem>
                      <Typography sx={{ alignSelf: 'center' }}>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={clickRegister}
                          disabled={adultCount <= 0}
                          startIcon={<SvgIcon component={BoatIcon} inheritViewBox />}
                        >
                          Anmelden
                        </Button>{' '}
                      </Typography>
                    </>
                  )}

                  {!confirmation && (
                    <>
                      <Typography sx={{ alignSelf: 'center' }}>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={clickUnRegister}
                          startIcon={<SvgIcon component={BoatIcon} inheritViewBox />}
                        >
                          Absagen
                        </Button>{' '}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </>
          )}

          {status === NETWORK_LOADED && userData.data?.responseType && userData.data?.responseType === 'cancelled' && (
            <>
              <Typography variant='body1' color='text.primary' sx={{ marginTop: 2, textAlign: 'center' }}>
                Schade, dass du nicht dabei sein kannst! <br />
                Du wirst uns fehlen. <br />
                Auf ein baldiges Wiedersehen!
              </Typography>
            </>
          )}

          {status === NETWORK_LOADED && userData.data?.responseType && userData.data?.responseType === 'confirmed' && (
            <>
              <Typography variant='body1' color='text.primary' sx={{ marginTop: 2, textAlign: 'center' }}>
                Wir freuen uns riesig mit Dir zu feiern!{' '}
              </Typography>
            </>
          )}
        </Container>
      </Box>

      <Dialog open={openConfirmation} onClose={handleClose}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>Du möchtest hiermit zusagen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <LoadingButton loading={loading} onClick={handleConfirmation}>
            Zusagen
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openCancellation} onClose={handleClose}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>Schade, du kannst nicht mit uns feiern?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <LoadingButton loading={loading} onClick={handleCancellation}>
            Absagen
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
