import * as React from 'react';
import { Box, SvgIcon, Card, Container, Typography, CircularProgress } from '@mui/material';

import { ReactComponent as KidsIco } from '../../assets/Four-Kids-And-Circle.svg'; //https://openclipart.org/detail/287924/four-kids-and-circle
export const ChildCare = () => {
  return (
    <>
     
        <Box
          sx={{               
           
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <SvgIcon
            component={KidsIco}
            inheritViewBox
            variant='outlined'
            sx={(theme) => ({
            
              fontSize: 120,
              paddingRight: 2,

              color: theme.palette.mode === 'light' ? 'black' : 'white',
            })}
            alt='Kids playing'
          />
          <Typography
            variant='body1'
            color='text.primary'
            sx={(theme) => ({
              marginTop: 2,
              width:'100vm',
           
            })}
          >
            Auch unsere kleinen Gäste sind herzlich willkommen.  <br />
            Magda ist unser Joker, sie wird etwas vorbereiten.  <br />
            Auch ein eigener Bereich für die Kinder ist geplant.


          </Typography>
        </Box>
      
    </>
  );
};
