import * as React from 'react';
import { Box, Button, Card, Typography, Container, Chip, Stack, Tooltip, IconButton, Grid } from '@mui/material';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AnchorIcon from '@mui/icons-material/Anchor';
import { WeddingMap } from './maps/maps';
import AltRouteSharpIcon from '@mui/icons-material/AltRouteSharp';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-medium-image-zoom/dist/styles.css';
import { useTheme } from '@mui/system';

import ShipRoute from './zoom/shipRoute';
import ShipIco from './zoom/ship';
const items = [
  {
    icon: <DirectionsBoatIcon />,
    title: 'Das Schiff',
    description: 'MS-Vindobona, gestaltet durch Friedensreich Hundertwasser',
    id: 'ship',
  },
  {
    icon: <AnchorIcon />,
    title: 'Ort',
    id: 'map',
    description: 'Schiffstation Wien / Schwedenplatz<br />Anlegestelle Nr. 3<br />Franz Josefs Kai 2, 1010 Wien',
  },
  {
    icon: <AltRouteSharpIcon />,
    title: 'Die Route',
    description: 'Den Donaukanal flussabwärts, dann die Donau flussaufwärts bis Nussdorf und wieder den Donaukanal zurück',
    id: 'route',
  },
];

export default function Features() {
  const theme = useTheme();
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id='location' sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component='h2' variant='h4' color='text.primary'>
              Location - MS Vindobona
            </Typography>
            <Typography variant='body1' color='text.secondary' sx={{ mb: { xs: 2, sm: 4 } }}>
              Unsere Hochzeit findet auf der MS Vindobona statt.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant='outlined'
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            {selectedItemIndex == 0 && (
              <>
                <ShipIco></ShipIco>
              </>
            )}
            {selectedItemIndex == 1 && (
              <>
                <WeddingMap sxMap={{ height: '400px' }} />
              </>
            )}
            {selectedItemIndex == 2 && (
              <>
                <ShipRoute></ShipRoute>
              </>
            )}

            <Box sx={{ px: 2, pb: 2, pt: 2 }}>
              <Typography color='text.primary' variant='body2' fontWeight='bold'>
                {selectedFeature.title}
              </Typography>
              <Typography color='text.secondary' variant='body2' sx={{ my: 0.5 }}>
                <div dangerouslySetInnerHTML={{ __html: selectedFeature.description }}></div>
              </Typography>
              {selectedItemIndex == 1 && (
                <>
                  <CopyToClipboard text='Franz Josefs Kai 2, 1010 Wien'>
                    <Tooltip title={'Adresse in Zwischenablage kopieren'}>
                      <IconButton aria-label='CopyToClipboard'>
                        <ContentCopySharpIcon />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </>
              )}
            </Box>
          </Box>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='flex-start'
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description, id }, index) => (
              <Card
                key={index}
                variant='outlined'
                component={Button}
                id={id}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor: selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index ? 'primary.main' : 'grey.300';
                        }
                        return selectedItemIndex === index ? 'primary.main' : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>

                  <Box sx={{ textTransform: 'none' }}>
                    <Typography color='text.primary' variant='body1' fontWeight='bold'>
                      {title}
                      {index == 1 && (
                        <>
                          <CopyToClipboard text='Franz Josefs Kai 2, 1010 Wien'>
                            <Tooltip title={'Adresse in Zwischenablage kopieren'}>
                              <IconButton
                                size='small'
                                aria-label='CopyToClipboard'
                                sx={{ marginLeft: 2 }}
                                color='text.secondary'
                              >
                                <ContentCopySharpIcon fontSize='inherit' />
                              </IconButton>
                            </Tooltip>
                          </CopyToClipboard>{' '}
                        </>
                      )}{' '}
                    </Typography>
                    <Typography color='text.secondary' variant='body1' sx={{ my: 0.5 }}>
                      <div dangerouslySetInnerHTML={{ __html: description }}></div>
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          {selectedItemIndex == 0 && (
            <>
              <ShipIco></ShipIco>
            </>
          )}
          {selectedItemIndex == 1 && (
            <>
              <WeddingMap sxMap={{ height: '500px' }} />
            </>
          )}
          {selectedItemIndex == 2 && (
            <>
              <ShipRoute></ShipRoute>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
