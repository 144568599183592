import * as React from 'react';
import { alpha } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';

export default function WaitingForData() {
  return (
    <Box
      id='Waiting'
      sx={(theme) => ({
        width: '100%',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 30 },
        }}
      >
        <CircularProgress />
      </Container>
    </Box>
  );
}
