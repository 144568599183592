import * as React from 'react';

import Container from '@mui/material/Container';
import { useContext } from 'react';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as LogoIcon } from '../assets/logo_simple.svg';
import { AuthContext, NETWORK_LOADED } from '../context/AuthenticationProvider';

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' mt={1}>
      {'Copyright © '}
      <Link href='https://marina.co.at/'>Marina&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  const { instance, status, userData } = useContext(AuthContext);

  const scrollToSection = (sectionId, clickOn) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });

      if (clickOn) {
        const clickItem = document.getElementById(clickOn);
        clickItem.click();
      }
      setOpen(false);
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // gap: { xs: 4, sm: 8 },
        //  py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      {status === NETWORK_LOADED && userData.data?.guestType && userData.data?.guestType !== 'unknown' && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                minWidth: { xs: '100%', sm: '60%' },
              }}
            >
              <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                <Box sx={{ ml: '-15px' }}>
                  <SvgIcon
                    component={LogoIcon}
                    inheritViewBox
                    sx={(theme) => ({
                      fontSize: 150,
                      cursor: 'pointer',
                      margin: 2,
                      color: theme.palette.mode === 'light' ? 'black' : 'white',
                    })}
                    alt='Marina Wedding Logo'
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                marginTop: 2,
                gap: 1,
              }}
            >
              <Typography variant='body2' fontWeight={600}>
                Übersicht
              </Typography>
              <Tooltip title={'Weitere Details zum Ort'}>
                <Link color='text.secondary' onClick={() => scrollToSection('location', null)}>
                  Location
                </Link>
              </Tooltip>
              <Link color='text.secondary' onClick={() => scrollToSection('timetable', null)}>
                Zeitplan
              </Link>
              <Tooltip title={'Kontaktmöglichkeiten'}>
                <Link color='text.secondary' onClick={() => scrollToSection('contacts', null)}>
                  Kontakte
                </Link>
              </Tooltip>
              <Tooltip title={'Weitere Infos'}>
                <Link color='text.secondary' onClick={() => scrollToSection('faq', null)}>
                  Q & A
                </Link>
              </Tooltip>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              //   pt: { xs: 4, sm: 8 },
              width: '100%',
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            <div>
              <Copyright />
            </div>
            <Stack
              direction='row'
              justifyContent='left'
              spacing={1}
              useFlexGap
              sx={{
                color: 'text.secondary',
              }}
            ></Stack>
          </Box>
        </>
      )}
      {status === NETWORK_LOADED && userData.data?.guestType && userData.data?.guestType === 'unknown' && (
        <>
          <div>
            <Copyright />
          </div>
        </>
      )}
      {status !== NETWORK_LOADED && (
        <>
          <div>
            <Copyright />
          </div>
        </>
      )}
    </Container>
  );
}
