import { APIProvider, Map, AdvancedMarker, useAdvancedMarkerRef, Pin, InfoWindow } from '@vis.gl/react-google-maps';
import * as React from 'react';
import Box from '@mui/material/Box';
import LogoIcon2 from '../../assets/boat.svg';

export const WeddingMap = ({ sxMap }) => {
  const StStephensCathedral = {
    lat: 48.208620675415816,
    lng: 16.37342318169971, //Stephansdom
  };

  const vindobona = {
    lat: 48.21198139148364,
    lng: 16.37843976332114,
  };

  const beachFlagImg = document.createElement('img');

  beachFlagImg.src = LogoIcon2;
  beachFlagImg.height = 30;
  beachFlagImg.width = 30;

  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infowindowShown, setInfowindowShown] = React.useState(false);

  const toggleInfoWindow = () => setInfowindowShown((previousState) => !previousState);

  const closeInfoWindow = () => setInfowindowShown(false);

  const gKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  return (
    <APIProvider apiKey={gKey}>
      <Map defaultCenter={StStephensCathedral} defaultZoom={14} style={sxMap} mapId={'WeddingMap'}>
        <AdvancedMarker
          title={'MS Vindobona, Liegeplatz 3'}
          position={vindobona}
          onClick={toggleInfoWindow}
          ref={markerRef}
        >
          {infowindowShown && (
            <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
              <Box
                sx={(theme) => ({
                  color: 'black'
                })}
              >
                <b>Unser Schiff - MS Vindobona</b>
                <br />
                Liegeplatz 3<br />
                <a href='https://maps.app.goo.gl/RRytSh2kp46ZqBz67' target='_blank' rel='noreferrer'>
                  In Google Maps öffnen
                </a>
              </Box>
            </InfoWindow>
          )}
          <Pin background={'#259faf'} glyphColor={'#000'} borderColor={'#000'} scale={1.5} glyph={beachFlagImg}></Pin>
        </AdvancedMarker>
      </Map>
    </APIProvider>
  );
};
