import * as React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';
import { WeddingSchedule } from './timeschedule/schedule';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id='timetable'
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component='h2'
        variant='h4'
        color='text.primary'
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Zeitplan
      </Typography>
      <Typography
        component='h2'
        variant='h6'
        color='text.secondary'
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        für den 28. Sep. 2024
      </Typography>
      <Box sx={{ width: '100%' }}>
        <WeddingSchedule></WeddingSchedule>
      </Box>
    </Container>
  );
}
