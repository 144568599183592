import './App.css';
import { AuthenticationProvider } from './context/AuthenticationProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AdminGrid } from './pages/AdminGuests/admin';
import LandingPage from './LandingPage';

function App() {
  //https://mui.com/material-ui/getting-started/templates/landing-page/
  return (
    <AuthenticationProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />

          <Route path='_admin' element={<AdminGrid />} />
          <Route path='*' element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </AuthenticationProvider>
  );
}

export default App;
