import React, { createContext, useEffect, useState } from 'react';
import { InteractionRequiredAuthError, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { getAccount } from './getAccount';
import LoginIcon from '@mui/icons-material/Login';
import { IconButton } from '@mui/material';
import { request } from '../components/api/api';

const clientId = process.env.REACT_APP_CLIENT_ID;

const redirectUri = process.env.REACT_APP_REDIRECT_URL;
const tenantName = process.env.REACT_APP_TENANT_NAME;
const instanceName = process.env.REACT_APP_AZURE_INSTANCE;

export const NETWORK_PRISTINE = 'NETWORK_PRISTINE';
export const NETWORK_PENDING = 'NETWORK_PENDING';
export const NETWORK_LOADED = 'NETWORK_LOADED';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const NETWORK_ANONYMOUS = 'NETWORK_ANONYMOUS';

export const instanceConfig = {
  auth: {
    clientId,
    authority: `https://${instanceName}/${tenantName}.onmicrosoft.com/${process.env.REACT_APP_AZURE_POLICY_ID}/`,
    redirectUri,
    knownAuthorities: [instanceName],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    claimsBasedCachingEnabled: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
          // console.log(message)
        }
      },
    },
  },
};

export const tokenConfig = {
  scopes: [`https://${tenantName}.onmicrosoft.com/${clientId}/access_as_user`],
};

export const redirectToLogin = (instance) =>
  instance.loginRedirect({
    extraQueryParameters: {
      ui_locales: `de`,
    },
  });

export const redirectToLogout = (instance) =>{
  
  instance.logoutRedirect({
    extraQueryParameters: {
      ui_locales: `de`,
    },
  });
};

export const getSession = async (instance) => {
  try {
    return await instance.acquireTokenSilent(tokenConfig);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect(tokenConfig);
    }
    throw error;
  }
};

export const requestSession = async (instance) => {
  const account = await getAccount(instance);

  if (!account) {
    await redirectToLogin(instance);
    return null;
  }
  return getSession(instance);
};
export const AuthContext = createContext({});

export const AuthenticationProvider = ({ children }) => {
  const [status, setStatus] = useState(NETWORK_PRISTINE);
  const [session, setSession] = useState(null);
  const [currentUser, setCurrentUser] = useState(0);
  const [userData, setUserData] = useState(0);
  const [instance, setInstance] = useState({});

  useEffect(() => {
    const instance = new PublicClientApplication(instanceConfig);

    const fn = async () => {
      setStatus(NETWORK_PENDING);
      await instance.initialize();
      setInstance(instance);
      try {
        const account = await getAccount(instance);
        const activeAccount = instance.getActiveAccount();
        if (activeAccount !== null) {
          
          setStatus(NETWORK_LOADED);
          const newSession = await requestSession(instance);
          if (newSession) {
            setSession(newSession);
          }
          setCurrentUser(activeAccount.idTokenClaims.given_name + ' ' + activeAccount.idTokenClaims.family_name);
          const whoAmIResult = await request(instance, { url: '/info/whoami', params: {} });
          setUserData(whoAmIResult);

        } else {
          setStatus(NETWORK_ANONYMOUS);
        }
      } catch (error) {
        console.log(error);
        setStatus(NETWORK_ERROR);
        return null;
      }
    };
    fn();
  }, []);
  // if (status === NETWORK_ERROR ) {
  //   return (
  //     <div>
  //       <div
  //         onClick={async () => {
  //           redirectToLogin(instance);
  //         }}
  //       >
  //         <p>Hier findest du alle Details zu unsere geplanten Hochzeit</p>
         
  //         Möchtest du mehr wissen? Dann melde dich hier an: {' '}
  //         <IconButton color="primary">
  //           <LoginIcon />
  //         </IconButton>

  //         <p><i>Du kannst dich ganz einfach selbst registrieren.<br/>Der Anmeldeprozess sollte in 1-2 Minutena abgeschlossen sein.<br/>Dann bekommst du alle Informationen</i></p>
  //       </div>
  //     </div>
  //   );
  // }
 // if (!session || status !== NETWORK_LOADED) return <div>loading</div>;

  return <AuthContext.Provider value={{ instance, currentUser, userData, status }}>{children}</AuthContext.Provider>;
};
