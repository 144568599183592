import * as React from 'react';
import { useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, SvgIcon, CircularProgress, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Unauthenticated from './components/Unauthenticated';
import { Booking } from './components/Booking/booking';
import Location from './components/Location';
import TimeTable from './components/TimeTable';
import Contacts from './components/Contacts';
import FAQ from './components/FAQ';

import Footer from './components/Footer';
import { AuthContext, NETWORK_LOADED } from './context/AuthenticationProvider';
import WaitingForData from './components/waitingForData';

export default function LandingPage() {
  const [mode, setMode] = React.useState('light');
  const { instance, status, userData } = useContext(AuthContext);
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      {status !== NETWORK_LOADED && (
        <>
          <Unauthenticated />
          <Box sx={{ bgcolor: 'background.default' }}>
            <Divider />
            <Footer />
          </Box>
        </>
      )}

      {status === NETWORK_LOADED && (
        <>
          <Hero />
          <Box sx={{ bgcolor: 'background.default' }}>
            <Divider />

            {status === NETWORK_LOADED && !userData.data && (
                <>
                  <WaitingForData />
                </>
              )}

            {status === NETWORK_LOADED && userData.data?.guestType && userData.data?.guestType !== 'unknown' && (
              <>
                
                <Location />
                <Divider />
                <TimeTable></TimeTable>
                <Divider />
                <Booking />
                <Divider />
                <Contacts />
                {/*   <Divider />
              <Highlights />
              <Divider />
              <Pricing />*/}
                <Divider />
                <FAQ />
                <Divider />
              </>
            )}
            <Footer />
          </Box>
        </>
      )}
    </ThemeProvider>
  );
}
