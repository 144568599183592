import * as React from 'react';
import { alpha } from '@mui/material';
import { useContext } from 'react';
import { Box, SvgIcon } from '@mui/material';
import Container from '@mui/material/Container';
import imageWe from '../assets/we.png';
import { ReactComponent as Logo } from '../assets/logo_wedding.svg';
import { ReactComponent as LogoMobile } from '../assets/logo_wedding_mobile.svg';
import { AuthContext, NETWORK_LOADED } from '../context/AuthenticationProvider';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import { yellow } from '@mui/material/colors';
export default function Hero() {
  const { instance, status, userData } = useContext(AuthContext);

  return (
    <Box
      id='hero'
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <SvgIcon
            component={Logo}
            inheritViewBox
            variant='outlined'
            sx={(theme) => ({
              width: '100%',
              fontSize: 300,
              margin: 2,

              color: theme.palette.mode === 'light' ? 'black' : 'white',
            })}
            alt='Marina Wedding Logo'
          />
        </Box>

        <Box sx={{ display: { sm: '', md: 'none' } }}>
          <SvgIcon
            component={LogoMobile}
            inheritViewBox
            variant='outlined'
            sx={(theme) => ({
              width: '100%',
              fontSize: 300,

              color: theme.palette.mode === 'light' ? 'black' : 'white',
            })}
            alt='Marina Wedding Logo'
          />
        </Box>
        {status === NETWORK_LOADED && userData.data?.guestType && userData.data?.guestType !== 'unknown' && (
          <>
            <Box
              id='image'
              sx={(theme) => ({
                mt: { xs: 8, sm: 10 },

                alignSelf: 'center',
                height: { xs: 200, sm: 500 },
                width: '80%',
                backgroundPosition: 'center',
                backgroundImage: theme.palette.mode === 'light' ? 'url(' + imageWe + ')' : 'url(' + imageWe + ')',
                backgroundSize: 'cover',
                borderRadius: '10px',
                outline: '1px solid',
                outlineColor: theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                boxShadow:
                  theme.palette.mode === 'light'
                    ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                    : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
              })}
            />
          </>
        )}

        {status === NETWORK_LOADED && userData.data?.guestType && userData.data?.guestType === 'unknown' && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyItems: 'center' }}>
              <ErrorOutlineIcon sx={{ fontSize: 90, color: yellow[500], paddingRight: '20px' }} />
              <Typography
                component='h2'
                variant='body1'
                color='text.primary'
                sx={{
                  textAlign: { sm: 'left', md: 'center' },
                }}
              >
                Hallo, du hast dich erfolgreich registriert. <br />
                Die automatische Gästerkennung hat bei dir leider nicht funktioniert. <br />
                Die kleinen Heinzelmännchen werden dich demnächst freischalten.
              </Typography>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}
