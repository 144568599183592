import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import base64 from 'base-64';
import utf8 from 'utf8';
import { IconButton, Tooltip } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
function GetContact(data) {
  const buffer = base64.decode(data);
  const decodedText = utf8.decode(buffer);
  return 'mailto:' + decodedText;
}

function Trauzeuge() {
  const val = GetContact('dHJhdXpldWdlQG1hcmluYS5jby5hdA==');
  window.location = val;
}
function TrauzeugeIn() {
  const val = GetContact('dHJhdXpldWdpbkBtYXJpbmEuY28uYXQ=');
  window.location = val;
}
function Wedding() {
  const val = GetContact('d2VkZGluZ0BtYXJpbmEuY28uYXQ=');
  window.location = val;
}

const userContacts = [
  {
    name: 'Astrid',
    occupation: 'Trauzeugin',
    testimonial: '',
    onClick: TrauzeugeIn,
  },
  {
    name: 'Christian',
    occupation: 'Trauzeuge',
    testimonial: '',
    onClick: Trauzeuge,
  },
  {
    name: 'Sabrina & Markus',
    occupation: 'Brautpaar',
    testimonial: '',
    onClick: Wedding,
  },
];

export default function Contacts() {
  const theme = useTheme();

  return (
    <Container
      id='contacts'
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          Kontakte
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          Hier findet ihr unsere wichtigsten Kontakte
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userContacts.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
             sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
                background:
                theme.palette.mode === 'light'
                  ? 'linear-gradient(to right bottom, #FFF, #CEE5FD)'
                  :'linear-gradient(to right bottom, #000, #02294F)'

              })}
            >
              <CardContent>
                <Typography variant='body2' color='text.secondary'>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader avatar={testimonial.avatar} title={testimonial.name} subheader={testimonial.occupation} />
                <CardActions disableSpacing>
                  <Tooltip title={'Mail an ' + testimonial.name + ' senden'}>
                    <IconButton aria-label='Mail senden' onClick={() => testimonial.onClick()}>
                      <AlternateEmailIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
