import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip } from '@mui/material';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

function ToggleColorMode({ mode, toggleColorMode }) {
  return (
    <Box sx={{ maxWidth: '32px' }}>
      <Button
        variant='text'
        onClick={toggleColorMode}
        size='small'
        aria-label='button to toggle theme'
        sx={{ minWidth: '32px', height: '32px', p: '4px' }}
      >
        {mode === 'dark' ? (
          <Tooltip title='Tagmodus aktivieren'>
            <WbSunnyRoundedIcon fontSize='small' />
          </Tooltip>
        ) : (
          <Tooltip title='Nachtmodus aktivieren'>
            <ModeNightRoundedIcon fontSize='small' />
          </Tooltip>
        )}
      </Button>
    </Box>
  );
}

ToggleColorMode.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default ToggleColorMode;
