import { getSession } from '../../context/AuthenticationProvider';
import { stringify } from 'qs';
import axios from 'axios';

const SERIALIZE_OPTIONS = { skipNulls: true };
const paramsSerializer = (params) => stringify(params, SERIALIZE_OPTIONS);

const getOptions = (arg) => (typeof arg === 'string' ? { url: arg } : arg);
export const request = async (instance, arg, isFormData = false) => {
  const api = process.env.REACT_APP_API_BASE;
  const options = getOptions(arg);
  const session = await getSession(instance);
  const { accessToken } = session;

  const response = await axios({
    paramsSerializer: { serialize: paramsSerializer },
    baseURL: api,
    headers: {
      'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    ...options,
  });

  return response.data;
};

// example get and post request
// request(instance, {url,params})
// request(instance, {url,data,method:'post'})
