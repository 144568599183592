import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, SvgIcon, CircularProgress, Tooltip } from '@mui/material';
import { useContext, useEffect, setState, styled } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {
  AuthContext,
  NETWORK_LOADED,
  redirectToLogout,
  redirectToLogin,
} from '../context/AuthenticationProvider';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import { ReactComponent as LogoIcon } from '../assets/logo_simple.svg';

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const { instance, status, userData } = useContext(AuthContext);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId, clickOn) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      if (clickOn) {
        const clickItem = document.getElementById(clickOn);
        clickItem.click();
      }
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth='lg'>
          <Toolbar
            variant='regular'
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <SvgIcon
                component={LogoIcon}
                inheritViewBox
                sx={(theme) => ({
                  fontSize: 100,
                  cursor: 'pointer',
                  margin: 2,
                  color: theme.palette.mode === 'light' ? 'black' : 'white',
                })}
                alt='Marina Wedding Logo'
              />
              {status === NETWORK_LOADED && !userData.data && (
                <>
                  <CircularProgress />
                </>
              )}

              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {status === NETWORK_LOADED && userData.data?.guestType && userData.data?.guestType !== 'unknown' && (
                  <>
                    <MenuItem onClick={() => scrollToSection('location', null)} sx={{ py: '6px', px: '12px' }}>
                      <Tooltip title={'Weitere Details zum Ort'}>
                        <Typography variant='body2' color='text.primary'>
                          Location
                        </Typography>
                      </Tooltip>
                    </MenuItem>

                    <MenuItem onClick={() => scrollToSection('timetable', null)} sx={{ py: '6px', px: '12px' }}>
                      <Typography variant='body2' color='text.primary'>
                        Zeitplan
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection('contacts', null)} sx={{ py: '6px', px: '12px' }}>
                      <Tooltip title={'Kontaktmöglichkeiten'}>
                        <Typography variant='body2' color='text.primary'>
                          Kontakte
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection('faq', null)} sx={{ py: '6px', px: '12px' }}>
                      <Tooltip title={'Weitere Infos'}>
                        <Typography variant='body2' color='text.primary'>
                          Q & A
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />

              {status !== NETWORK_LOADED && (
                <>
                  <Button
                    color='primary'
                    variant='text'
                    size='small'
                    component='a'
                    target='_blank'
                    onClick={() => {
                      redirectToLogin(instance);
                    }}
                  >
                    Anmelden
                  </Button>
                </>
              )}

              {status === NETWORK_LOADED && (
                <>
                  <Button
                    color='primary'
                    variant='contained'
                    size='small'
                    component='a'
                    onClick={() => {
                      redirectToLogout(instance);
                    }}
                    target='_blank'
                  >
                    Abmelden
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant='text'
                color='primary'
                aria-label='menu'
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  </Box>
                  {status === NETWORK_LOADED && userData.data?.guestType && userData.data?.guestType !== 'unknown' && (
                    <>
                      <MenuItem onClick={() => scrollToSection('location', null)}>Location</MenuItem>
                      <MenuItem onClick={() => scrollToSection('location', 'schedule')}>Zeitplan</MenuItem>
                    </>
                  )}

                  <Divider />

                  {status !== NETWORK_LOADED && (
                    <>
                      <MenuItem>
                        <Button
                          color='primary'
                          variant='text'
                          sx={{ width: '100%' }}
                          component='a'
                          target='_blank'
                          onClick={() => {
                            redirectToLogin(instance);
                          }}
                        >
                          Anmelden
                        </Button>
                      </MenuItem>
                    </>
                  )}

                  {status === NETWORK_LOADED && (
                    <>
                      {' '}
                      <MenuItem>
                        <Button
                          color='primary'
                          variant='contained'
                          sx={{ width: '100%' }}
                          component='a'
                          onClick={() => {
                            redirectToLogout(instance);
                          }}
                          target='_blank'
                        >
                          Abmelden
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
