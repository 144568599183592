import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChildCare } from './childCare/childCare';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id='faq'
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component='h2'
        variant='h4'
        color='text.primary'
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Weitere Infos
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion expanded={expanded === 'pShip'} onChange={handleChange('pShip')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='pShip-content' id='pShip-header'>
          <Typography color='text.primary' variant='body1' fontWeight='bold'>
            Wo findet was statt?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
   
            <Typography variant='body1' gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Sowohl die Trauung als auch Festessen und Party finden am Schiff statt. <br />
            <br />
             Zwischendurch sind wir für ca. 3h unterwegs. Das Schiff wird für die verbleibende Zeit am Schwedenplatz vor Anker liegen.
             
             
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'pKids'} onChange={handleChange('pKids')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='pKids-content' id='pKids-header'>
         <Typography color='text.primary' variant='body1' fontWeight='bold'>
            Sind Kinder erwünscht? Gibt es ein Programm?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChildCare />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'pDresscode'} onChange={handleChange('pDresscode')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='pDresscode-content' id='pDresscode-header'>
         <Typography color='text.primary' variant='body1' fontWeight='bold'>
            Was soll ich anziehen? Gibt es einen Dresscode?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body1' gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
              <Grid style={{ display: 'flex', alignItems: 'center' }}>
              Es gibt bei unserer Hochzeit keinen Dresscode, kommt bitte worin ihr euch wohl fühlt.<br/> 
              (wenn es leicht geht, aber vielleicht kein Jogginganzug &#128521;)
               
              </Grid>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'pPresents'} onChange={handleChange('pPresents')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='pPresents-content' id='pPresents-header'>
         <Typography color='text.primary' variant='body1' fontWeight='bold'>
            Was wünscht ihr euch zur Hochzeit?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body1' gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
              Am meisten freuen wir uns, wenn ihr diesen Tag gemeinsam mit uns feiert. <br />
              Solltet ihr uns trotzdem etwas schenken wollen, freuen wir uns über Geldgeschenke für die Hochzeitsreise.{' '}
              <br />
              Wenn ihr möchtet, könnt ihr euch auch bei unseren Trauzeugen melden, <br />
              die organisieren für uns ein gemeinsames Geschenk für unsere nächste Tauchreise.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'pPictures'} onChange={handleChange('pPictures')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='pPictures-content' id='pPictures-header'>
         <Typography color='text.primary' variant='body1' fontWeight='bold'>
            Ist fotografieren auf der Hochzeit erlaubt?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body1' gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Während der Trauung begleitet uns ein Fotografen-Team. <br />Günther wird danach für uns weitere Fotos machen.
              <br />
              <br />
              Ihr dürft natürlich auch gerne selbst Fotos machen.     <br />Wir freuen uns, wenn ihr uns diese nachträglich zur Verfügung stellt.
              <br />    <br />
Wir werden hier nach der Hochzeit den Link zu den Fotos bereitstellen.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'pConfirmation'} onChange={handleChange('pConfirmation')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='pConfirmation-content'
            id='pConfirmation-header'
          >
         <Typography color='text.primary' variant='body1' fontWeight='bold'>
              Bis wann soll ich Zu- bzw. Absagen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body1' gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
             
                Am besten gleich &#128521;<br /><br />
             
                Wir hätten gerne eine Rückmeldung bis spätestens 16. Juni 2024
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'pSpecialNeeds'} onChange={handleChange('pSpecialNeeds')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='pSpecialNeeds-content'
            id='pSpecialNeeds-header'
          >
         <Typography color='text.primary' variant='body1' fontWeight='bold'>
            Gibt es für mich (Vegetarier:in/Veganer:in, etc.) auch etwas zu essen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body1' gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
            Solltet Ihr besondere Wünsche haben, schreibt diese bitte bei der Anmeldung unter „Besondere Bedürfnisse“ dazu, dann können wir diese mit der Board-Gastronomie (Küche direkt am Schiff) vorher abstimmen.<br /><br />
Es wird aber auf jeden Fall eine Auswahl für Veganer:innen und Vegetarier:innen geben.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
