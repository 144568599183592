import { InteractionRequiredAuthError } from '@azure/msal-browser';

const selectAccount = (instance, account) => {
  instance.setActiveAccount(account);
  return account;
};

const getCachedAccount = (instance) => {
  const activeAccount = instance.getActiveAccount();
  if (activeAccount) return activeAccount;

  const accounts = instance.getAllAccounts();
  if (accounts && accounts.length) return accounts[0];

  return null;
};

export const getAccount = async (instance) => {
  // Deals with after-redirect logic
  const response = await instance.handleRedirectPromise();
  if (response !== null) return selectAccount(instance, response.account);

  // Tries to return cached account
  const account = getCachedAccount(instance);
  if (account) return selectAccount(instance, account);

  // Tries to reactivate account silently
  try {
    const silentReactivation = await instance.ssoSilent({});
    return selectAccount(instance, silentReactivation.account);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) return null;
    throw error;
  }
};
