import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { request } from '../../components/api/api';
import { AuthContext, NETWORK_LOADED } from '../../context/AuthenticationProvider';
import { useContext, useEffect, setState } from 'react';
import { Box, Button, SvgIcon, CircularProgress } from '@mui/material';
import { ReactComponent as BoatIcon } from '../../assets/boat.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import clsx from 'clsx';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [...oldRows, { id, firstName: '', lastName: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'firstName' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
        Neu
      </Button>
    </GridToolbarContainer>
  );
}

export const AdminGrid = () => {
  const { status, userData, instance } = useContext(AuthContext);
  const [totalCount, setTotalCount] = React.useState(0);
  const [summary, setSummary] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const clickSummary = async (event) => {
    const response = await request(instance, { url: '/guests/summary' });
    setSummary(
      'Anmeldungen: ' +
        response.confirmations +
        ', Absagen: ' +
        response.cancellations +
        ', Gäste: ' +
        response.adults +
        ', Kids: ' +
        response.kids
    );
    console.log(response);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const url = newRow.isNew ? '/guests/add' : '/guests/edit';
    const updatedRow = { ...newRow, isNew: false };

    const response = await request(instance, { url: url, data: updatedRow, method: 'post' });

    setRows(rows.map((row) => (row.id === newRow.id ? response : row)));
    return response;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      //const newRows = await loadServerRows(page, data.current)

      if (!active) {
        return;
      }
      const response = await request(instance, {
        url: '/guests',
        params: { pageId: paginationModel.page, size: paginationModel.pageSize },
      });

      setRows(response.data.guests);
      setTotalCount(response.data.count);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel]);

  const columns = [
    { field: 'firstName', headerName: 'Vorname', editable: true },
    { field: 'lastName', headerName: 'Nachname', editable: true },

    {
      field: 'guestType',
      headerName: 'Permission',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['admin', 'guest'],
      align: 'center',
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('wedding-guestType', {
          guest: params.value === 'guest',
          admin: params.value === 'admin',
        });
      },
    },
    {
      field: 'responseType',
      headerName: 'Zu/Absage',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['unknown', 'confirmed', 'cancelled'],
      align: 'center',

      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('wedding-responseType', {
          unknown: params.value === 'unknown',
          confirmed: params.value === 'confirmed',
          cancelled: params.value === 'cancelled',
        });
      },
      renderCell: (params) => {
        switch (params.row.responseType) {
          case 'unknown':
            return <QuestionMarkIcon />;
          case 'confirmed':
            return <CheckCircleIcon />;
          case 'cancelled':
            return <CancelIcon />;
          default:
            return '';
        }
      },
    },
    {
      field: 'adults',
      headerName: 'Erw.',
      type: 'number',
      description: 'Erwachsene',
      align: 'center',
      editable: true,
      cellClassName: (params) => {
        return params.value > 0 ? 'wedding-guest' : '';
      },
    },
    {
      field: 'firstChildAge',
      headerName: '1. Kind',
      type: 'number',
      description: 'Alter des 1. Kindes',
      align: 'center',

      editable: true,
      cellClassName: (params) => {
        return params.value > 0 ? 'wedding-guest' : '';
      },
    },

    {
      field: 'secondChildAge',
      headerName: '2. Kind',
      type: 'number',
      description: 'Alter des 2. Kindes',
      align: 'center',

      editable: true,
      cellClassName: (params) => {
        return params.value > 0 ? 'wedding-guest' : '';
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      description: 'Besondere Bedürfnisse',
      editable: true,
      cellClassName: (params) => {
        return params.value ? 'wedding-guest' : '';
      },
    },
    { field: 'id', headerName: 'ID', editable: true },
    {
      field: 'azureId',
      headerName: 'AD',
      editable: true,

      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('wedding-azure', {
          unknown: params.value === '00000000-0000-0000-0000-000000000000',
          authenticated: params.value !== '00000000-0000-0000-0000-000000000000',
        });
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',

      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <>
              <GridActionsCellItem
                icon={<SaveIcon />}
                label='Save'
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />
            </>,
            <>
              <GridActionsCellItem
                icon={<CloseIcon />}
                label='Cancel'
                className='textPrimary'
                onClick={handleCancelClick(id)}
                color='inherit'
              />
            </>,
          ];
        }

        return [
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              className='textPrimary'
              onClick={handleEditClick(id)}
              color='inherit'
            />
          </>,
          // <>
          //   <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />
          // </>,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: '50%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        '& .wedding-guestType.guest': {
          backgroundColor: 'rgba(157, 255, 118, 0.49)',
          color: '#1a3e72',
          fontWeight: '600',
        },
        '& .wedding-guestType.admin': {
          backgroundColor: '#d47483',
          color: '#1a3e72',
          fontWeight: '600',
        },
        '& .wedding-responseType.unknown': {
          backgroundColor: 'rgba(108, 128, 192, 0.49)',
        },
        '& .wedding-responseType.confirmed': {
          backgroundColor: 'rgba(157, 255, 118, 0.49)',
        },
        '& .wedding-responseType.cancelled': {
          backgroundColor: 'rgba(197, 174, 152, 0.49)',
        },
        '& .wedding-azure.unknown': {
          backgroundColor: 'rgba(108, 128, 192, 0.49)',
        },

        '& .wedding-azure.authenticated': {
          backgroundColor: 'rgba(157, 255, 118, 0.49)',
        },
        '& .wedding-guest': {
          backgroundColor: 'rgba(157, 255, 118, 0.49)',
        },
      }}
    >
      <Button
        sx={{
          marginRight: 2,
          marginBottom: 1,
        }}
        variant='outlined'
        href='https://marina.co.at'
      >
        Home
      </Button>

      {status === NETWORK_LOADED && !userData.data && (
        <>
          <CircularProgress />
        </>
      )}

      {status === NETWORK_LOADED &&  userData.data?.guestType === 'admin' && (
        <>
          <Button
            sx={{
              marginRight: 2,
              marginBottom: 1,
            }}
            variant='outlined'
            onClick={clickSummary}
            startIcon={<SvgIcon component={BoatIcon} inheritViewBox />}
          >
            Statistik
          </Button>
          {summary}
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            paginationMode='server'
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 25, 100]}
            loading={loading}
            rowCount={totalCount}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
          />
        </>
      )}
    </Box>
  );
};
