import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useCallback, useState } from 'react';
import route_plan from '../../assets/ship_by_night.jpg';
import { useTheme } from '@mui/system';

import './zoom.css';

export default function ShipIco() {
  const [isZoomed, setIsZoomed] = useState(false);
  const theme = useTheme();
  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <Box
      id='shipRoute'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <ControlledZoom
        isZoomed={isZoomed}
        onZoomChange={handleZoomChange}
        classDialog={theme.palette.mode === 'light' ? 'custom-zoom-light' : 'custom-zoom-dark'}
      >
        <picture>
          <source media='(max-width: 800px)' srcSet={route_plan} />
          <img alt='MS Vindobona' src={route_plan} width='500' />
        </picture>
      </ControlledZoom>
    </Box>
  );
}
